import React from 'react';
import PropTypes from 'prop-types';

import SalesforceForm from './common/SalesforceForm';
import { FORM_TYPES } from '../../constants/forms';

const { CONTACT_US } = FORM_TYPES;

const propTypes = {
    title: PropTypes.string.isRequired,
    isModal: PropTypes.bool,
    onCancel: PropTypes.func,
};

const defaultProps = {
    isModal: false,
    onCancel: () => null,
};

const ContactUsForm = ({ title, isModal, onCancel }) => (
    <SalesforceForm id={CONTACT_US} title={title} onCancel={onCancel} isModal={isModal} />
);

ContactUsForm.propTypes = propTypes;
ContactUsForm.defaultProps = defaultProps;

export default ContactUsForm;

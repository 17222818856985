import React from 'react';
import { Helmet } from 'react-helmet';
import { Layout } from '../components/common';
import SEO from '../components/common/SEO';
import Customers from '../components/common/Customers';
import Banner from '../images/index/BannerWithoutBg.svg';
import ContactUsForm from '../components/forms/ContactUsForm';

import salesforceStyles from '../components/common/styles/salesforce.module.scss';

const Contact = () => (
    <Layout isHome title='Witbe - Leader in Quality of Experience'>
        <SEO
            title='Witbe - Leader in Quality of Experience'
            ogDescription='Witbe is the leader of Quality of Experience technology. We test & monitor any service, through any network, on any device.'
            description='Witbe is the leader of Quality of Experience technology. We test & monitor any service, through any network, on any device.'
        />
        <Helmet>
            <link rel='preload' href={Banner} as='image' type='image/svg+xml' />
        </Helmet>
        <div className={salesforceStyles.formWrapper}>
            <ContactUsForm title='Thank you for your interest!' />
        </div>
        <Customers />
    </Layout>
);

export default Contact;
